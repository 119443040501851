
.particles-box-footer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40vh;
    z-index: 1;
    user-select: none !important;
}

footer{
    z-index: 999;
    padding: 80px 15px;
    background-color: transparent;
}

footer .logo{
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    font-size: 45px;
    color: #ffffff;
    font-weight:900;
}

footer .social{
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding-top: 15px;
}

footer .social a{
    z-index: 9999;
    color: #999999;
    text-decoration: none;
    display: inline-block;
    margin: 0px 12px;
    transition: all .5s ease;
}

footer .social a:hover{
    color: #ffffff;
}

/*copyright*/

.copyright{
    background-color: transparent;
    padding: 15px; 
}

.copyright p{
    color: #838383;
    margin: 0;
    text-align: center;
}


@media screen and (max-width: 450px){
	.bgimage {
		width: 25vh;
		height: 150vh !important;
	}
}