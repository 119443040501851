.logo {
    width: 25%;
    
}

.name{
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  color: #000 !important;
}

.nav-theme {
  font-size: 20px;
  background-color: rgb(216, 66, 66) !important;
  /* box-shadow: 1px 2px #888888; */
}

.nav-link{
  text-decoration: none !important;
  color: black !important;
  cursor: pointer;
  user-select: none;
}

.animate-navbar {
  box-shadow: 1px 2px 10px #000;
  animation: moveDown 0.5s ease-in-out;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}