.name-font{
    font-size: 55px;
}

.sub {
    font-size: 28px;
    margin-top: 15px;
    letter-spacing: 2px;
}

.cv-button{
    z-index: 999;
    margin-top: 30px;
    padding-top: 25px !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    padding: 12px 25px !important;
    text-decoration: none !important;
    display: inline-block;
    font-size: 17px !important;
    font-weight: 600 !important;
    text-transform:uppercase !important;
    transition: all .5s ease !important;
    background-color: transparent !important;
}


.cv-button:hover{
    background-color: #ffffff !important;
    color: var(--alt-bg-color) !important;
}


.parag1{
	font-size: 1.4rem !important;
	max-width: 90% !important;
	margin-top: 10px;
}

@media screen and (max-width: 450px) {
    .name-font {
      font-size: 40px;
    }
    .sub{
        font-size: 24px;
    }
}

